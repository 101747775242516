<template>
    <ModalWrapper ref="modal" custom-close transparent @overlay-click="close" @close-modal="$emit('close-modal')">
        <div class="aoc-modal modal-window">
            <div class="modal-window__close" @click="close">
                <AppIcon icon="close"/>
            </div>
            <div class="aoc-modal__header default-heading">{{ headerText }}</div>
            <div class="aoc-modal__description">{{ descriptionText }}</div>
            <AppButton :theme="buttonTheme" ref="button" @click="close">
                {{ $t("components.common.itIsClear") }}
            </AppButton>
        </div>
    </ModalWrapper>
</template>
<script>
import ModalWrapper from "../partials/ModalWrapper";
import AppIcon from "../partials/AppIcon";
import AppButton from "../partials/AppButton";
export default {
    name: 'ActiveOperationCompletedModal',
    components: {AppButton, AppIcon, ModalWrapper},
    props: {
        success: Boolean,
        reason: String
    },
    computed: {
        headerText() {
            return this.success ? 'Активная операция выполнена' : 'Активная операция не выполнена. \nПроизошла ошибка.';
        },
        descriptionText() {
            if(this.reason === 'ABS' && !this.success) {
                return 'Операция не выполнена. Ошибка  iABS';
            }
            return this.success ? 'Активная операция успешно выполнена.\nМожете закрыть это окно.' : 'Попробуйте повторить ещё раз активную \nоперацию.';
        },
        buttonTheme() {
            return this.success ? 'success-flat' : 'danger';
        }
    },
    mounted() {
        this.$refs.button.$el.focus();
    },
    methods: {
        close() {
            this.$refs.modal.close();
        }
    }
}
</script>
<style lang="scss">
@import '../../assets/scss/variables';
.aoc-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 38px 45px 42px;
    &__header {
        margin-bottom: 34px;
        text-align: center;
        white-space: pre-wrap;
    }
    &__description {
        margin-bottom: 52px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.005em;
        font-family: $font-secondary;
        white-space: pre-wrap;
        color: #949494;
    }
}
</style>
